import React from "react";
import blurbDecoration from "../../assets/blurb-decoration.png";
import "./blurb.css";

const Blurb = () => (
  <div className="blurb-wrapper">
    <h1>
      TIGAR guides individualized therapy improving a patient’s metabolic health
    </h1>
    <p>
      TIGAR Health Technologies delivers a personalized medicine solution to
      provide more effective treatment of Type 2 diabetes and prediabetes. We
      analyze the patient's underlying metabolic regulatory pathways, and then
      integrate it with experts’ decision rules, patient patterns and key
      anti-diabetic drug information. In doing so, we transform metabolic
      medicine through personalized therapy regimens for each patient. Through
      our approach we can pick up undiagnosed conditions which are missed today,
      providing a more informed view of the patient.
    </p>
    <p>
      TIGAR reduces the complexity in choosing the right drugs by removing the
      trial and error method used today. Our individualized approach frees up
      time for a physician to interact with patients and leads the patient to
      fuller engagement and better health. We motivate patients by illuminating
      their underlying disease, and showing the best treatment strategies to
      address a patient’s situation.
    </p>
    <img className="tigar-stamp" src={blurbDecoration} alt="" />
  </div>
);

export default Blurb;
