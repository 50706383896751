import React from "react";
import "./caseStudies.css";

const CaseStudies = () => (
  <div className="case-studies-wrapper">
    <h1>
      TIGAR puts physicians in control of expert decision
      <br /> making for their diabetes patients.
    </h1>
  </div>
);

export default CaseStudies;
