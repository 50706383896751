import React from "react";
import "./splash.css";

const Splash = () => (
  <div className="splash-image-wrapper">
    <div className="splash-image" />
    <h1 className="splash-text">
      TIGAR Health Technologies Is
      <br /> Transforming Metabolic Medicine
    </h1>
  </div>
);

export default Splash;
