import React from "react";
import classNames from "classnames";
import "./pubsRow.css";

const PubsRow = ({ row, secondary }) => (
  <div className="pub-row-wrapper">
    {row.map(({ author, description, journal, link }, index) => (
      <a
        key={`${author}-${index}`}
        className={classNames(
          "pub-wrapper",
          secondary && "secondary",
          !author && "placeholder"
        )}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <p className="pub-description">{description}</p>
        <div className="pub-middle">
          <p className="pub-author">{author}</p>
          <p className="pub-journal">{journal}</p>
        </div>
        <p className="pub-link">{link && "Read paper"}</p>
      </a>
    ))}
  </div>
);

export default PubsRow;
