import React from "react";
import "./splash.css";

const Splash = () => (
  <div className="splash-about-wrapper">
    <div />
    <h1>
      TIGAR is a Breakthrough Technology
      <br /> for Targeted Therapy in Diabetes
    </h1>
  </div>
);

export default Splash;
