import React, { useState, useLayoutEffect } from "react";
import patient from "../assets/video.thumbnail.1.jpg";
import ceo from "../assets/ceo.placeholder.jpg";
import baumann from "../assets/baumann.placeholder.jpg";
import mallon from "../assets/mallon.placeholder.jpg";
import "./VideoWithPlaceholder.css";

const images = {
  patient,
  ceo,
  baumann,
  mallon,
};

const VideoWithPlaceholder = ({
  iframeTitle,
  imageKey,
  sectionTitle,
  videoSrc,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoWidth, setVideoWidth] = useState(window.innerWidth * 0.8);
  const [videoHeight, setVideoHeight] = useState(window.innerHeight * 0.6);
  const openModal = () => setIsModalOpen(true);
  const updateVideoDimensions = () => {
    setVideoWidth(window.innerWidth * 0.8);
    setVideoHeight(window.innerHeight * 0.6);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateVideoDimensions);
    return () => window.removeEventListener("resize", updateVideoDimensions);
  });

  return (
    <div className="video-with-placeholder-wrapper">
      <a
        id={`${sectionTitle.replaceAll(" ", "_").toLowerCase()}`}
        href={`#${sectionTitle.replaceAll(" ", "_").toLowerCase()}`}
      >
        <h1>{sectionTitle}</h1>
      </a>
      {isModalOpen ? (
        <div className="testimonial-modal">
          <iframe
            title={iframeTitle}
            src={videoSrc}
            width={videoWidth}
            height={videoHeight}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </div>
      ) : (
        <img onClick={openModal} src={images[imageKey]} alt="" />
      )}
    </div>
  );
};

export default VideoWithPlaceholder;
