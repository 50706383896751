import React from "react";
import "./style.css";

const Contact = () => (
  <div className="contact-wrapper">
    <h1>Contact Us</h1>
    <p>
      <b>TIGAR Health Technologies</b>
      <br />
      7 Lamp Court
      <br />
      Moraga, CA 94556
      <br />
      <a
        className="contact-mail"
        href="mailto:info@tigarhealth.com?subject=Information Request"
      >
        info@tigarhealth.com
      </a>
      <br />
      925-376-3128
      <br />
    </p>
  </div>
);

export default Contact;
