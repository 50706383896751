import React from "react";
import logo from "../../assets/blurb-decoration.png";
import "./summary.css";

const Summary = () => (
  <div className="solution-summary-wrapper">
    <h2>
      The TIGAR Report is a sophisticated tool delivered for quick & easy use,
      and makes drug therapy more effective:
    </h2>
    <ul>
      <li>
        <span>
          Addresses complex diabetes and prediabetes treatment optimization
          issues
        </span>
      </li>
      <li>
        <span>
          Integrates traditional metrics of glucose and kidney function, with
          pathophysiology driving disease
        </span>
      </li>
      <li>
        <span>
          Matches patient profile to one of over 35,000 unique patterns each
          linked to optimal anti-diabetic drugs
        </span>
      </li>
      <li>
        <span>
          Our ‘living system’ is based on the most current medical literature
        </span>
      </li>
      <li>
        <span>
          And, continued use monitors drug response and disease progression
          overtime
        </span>
      </li>
      <img className="tigar-stamp-solution" src={logo} alt="" />
    </ul>
  </div>
);

export default Summary;
