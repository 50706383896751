import React, { Component } from "react";
import Splash from "./splash";
import Management from "./management";

class About extends Component {
  render() {
    return (
      <div>
        <Splash />
        <Management />
      </div>
    );
  }
}

export default About;
