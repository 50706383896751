import React from "react";
import platform1 from "../../assets/platform1.png";
import platform2 from "../../assets/platform2.png";
import platform3 from "../../assets/platform3.png";
import platform4 from "../../assets/platform4.png";
import platform5 from "../../assets/platform5.png";
import platform6 from "../../assets/platform6.png";
import "./platform.css";

const Platform = () => (
  <div className="platform-wrapper">
    <h1 className="platform-header">
      The TIGAR Clinical Decision Support Platform
    </h1>
    <div className="platform-points-wrapper">
      <span>
        <img src={platform1} alt="" />
        <h2>
          Provides a New
          <br /> Perspective
        </h2>
        <p>
          First of its kind, unique approach using underlying disease analysis
          of metabolic pathway function.
        </p>
      </span>
      <span>
        <img src={platform2} alt="" />
        <h2>
          Complements
          <br /> Physician Judgment
        </h2>
        <p>
          Encourages physician to adjust therapy based on specific patient's
          metabolic profile.
        </p>
      </span>
      <span>
        <img src={platform3} alt="" />
        <h2>
          Guides Patient
          <br /> Personalization
        </h2>
        <p>
          Allows physician to make optimal treatment decisions for each patient
          as disease evolves.
        </p>
      </span>
      <span>
        <img src={platform4} alt="" />
        <h2>
          Delivers Sophisticated
          <br /> Assessment
        </h2>
        <p>
          TIGAR can be used as an assessment tool to uncover dysregulation much
          earlier for prediabetes.
        </p>
      </span>
      <span>
        <img src={platform5} alt="" />
        <h2>
          Improves Drug Class
          <br /> Selection
        </h2>
        <p>
          Our algorithm selects the drug classes that best target a patient's
          disease condition.
        </p>
      </span>
      <span>
        <img src={platform6} alt="" />
        <h2>
          Is Consistent with
          <br /> Guidelines
        </h2>
        <p>
          TIGAR adheres to the principles of the AACE Comprehensive Type 2
          Diabetes Management Algorithm
        </p>
      </span>
    </div>
  </div>
);

export default Platform;
