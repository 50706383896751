import React, { Component } from "react";
import PubsRow from "./pubsRow";
import "./pubsSection.css";

class PubsSection extends Component {
  state = { visibleRows: this.props.initialMax || this.props.rows.length };

  expandSection = () => {
    this.setState({ visibleRows: this.state.visibleRows + 1 });
  };

  shortenSection = () => {
    this.setState({ visibleRows: this.state.visibleRows - 1 });
  };

  renderRows = () => {
    const { rows, secondary } = this.props;
    const { visibleRows } = this.state;

    const formattedRows = [];

    for (let idx = 0; idx < visibleRows; idx++) {
      const row = rows[idx];
      formattedRows.push(<PubsRow key={idx} row={row} secondary={secondary} />);
    }

    return formattedRows;
  };

  render() {
    const { visibleRows } = this.state;
    const { initialMax, rows } = this.props;

    return (
      <div className="pubs-section-wrapper">
        {this.renderRows()}
        <div className="pubs-add-more-wrapper">
          {initialMax &&
            (rows.length <= visibleRows ? (
              <React.Fragment>
                <article onClick={this.shortenSection}>-</article>
                <h5 onClick={this.shortenSection}>Hide</h5>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <article onClick={this.expandSection}>+</article>
                <h5 onClick={this.expandSection}>More</h5>
              </React.Fragment>
            ))}
        </div>
      </div>
    );
  }
}

export default PubsSection;
