import React from "react";

const Ursula = ({ activeSlide }) => {
  switch (activeSlide) {
    case 0:
      return (
        <div className="list-split">
          <ul>
            <li>
              <span>
                Obese: <span className="danger">60 pounds overweight</span>
              </span>
            </li>
            <li>
              <span>
                Blood Pressure: <span className="danger">elevated</span>
              </span>
            </li>
            <li>
              <span>
                HbA1c: <span className="danger">6.8</span>{" "}
                {"vs. < 5.7 is normal"}
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                On Metformin <span className="danger">over 5 years</span>
              </span>
            </li>
            <li>
              <span>
                On Glimepiride <span className="danger">over 5 years</span>
              </span>
            </li>
            <li>
              <span>
                Condition <span className="danger">slowly deteriorating</span>
              </span>
            </li>
          </ul>
        </div>
      );
    case 1:
      return (
        <React.Fragment>
          <h2>TIGAR uncovers major dysfunction in Ursula</h2>
          <ul className="restricted-list">
            <li>
              <span className="spacer">
                Vascular Inflammation
                <span className="danger">5x normal</span>
              </span>
            </li>
            <li>
              <span className="spacer">
                Insulin Production (Beta-cell)
                <span className="danger">5x normal</span>
              </span>
            </li>
            <li>
              <span className="spacer">
                Fat & Muscle Response{" "}
                <span className="danger">50% of normal</span>
              </span>
            </li>
          </ul>
        </React.Fragment>
      );
    case 2:
      return (
        <React.Fragment>
          <h2>
            Physician <u>stopped</u> Glimepiride and <u>began</u> targeted
            therapy:
          </h2>
          <div className="list-split">
            <ul>
              <li>
                <span>
                  <span className="danger">
                    Added: <span className="normal">Pioglitazone (TZD)</span>
                  </span>
                </span>
              </li>
              <li>
                <span>
                  <span className="danger">Continued</span> Metformin
                </span>
              </li>
            </ul>
            <ul>
              <li>
                <span>
                  <span className="danger">
                    Added: <span className="normal">Liraglutide (GLP-1)</span>
                  </span>
                </span>
              </li>
              <li>
                <span>
                  <span className="danger">
                    Started <span className="normal">Low carb diet</span>
                  </span>
                </span>
              </li>
            </ul>
          </div>
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <h2>All measures improved</h2>
          {window.innerWidth > 600 ? (
            <div className="list-split expand triple">
              <ul>
                <li>
                  <span>Vascular Inflammation</span>
                </li>
                <li>
                  <span>Proinsulin Production</span>
                </li>
                <li>
                  <span>Fat and Muscle Response</span>
                </li>
                <li>
                  <span>Weight</span>
                </li>
                <li>
                  <span>Blood Pressure</span>
                </li>
                <li>
                  <span>HbA1c</span>
                </li>
              </ul>
              <ul className="no-list">
                <li>
                  <span>5.9 → 1.8</span>
                </li>
                <li>
                  <span>23.3 → 7.5</span>
                </li>
                <li>
                  <span>7.1 → 18.9</span>
                </li>
                <li>
                  <span>192 lbs → 174 lbs</span>
                </li>
                <li>
                  <span>125/80 → 120/80</span>
                </li>
                <li>
                  <span>6.8 → 6.4</span>
                </li>
              </ul>
              <ul className="no-list">
                <li>
                  <span className="improved">Almost Normal</span>
                </li>
                <li>
                  <span className="improved">Normal</span>
                </li>
                <li>
                  <span className="improved">Normal</span>
                </li>
                <li>
                  <span className="improved">Improved</span>
                </li>
                <li>
                  <span className="improved">Improved</span>
                </li>
                <li>
                  <span className="improved">Improved</span>
                </li>
              </ul>
            </div>
          ) : (
            <div className="list-split expand">
              <ul className="mobile">
                <li>
                  <span className="spacer">
                    Vascular Inflammation<span>5.9 → 1.8</span>
                    <span className="improved">Almost Normal</span>
                  </span>
                </li>
                <li>
                  <span className="spacer">
                    Proinsulin Production<span>23.3 → 7.5</span>
                    <span className="improved">Normal</span>
                  </span>
                </li>
                <li>
                  <span className="spacer">
                    Fat and Muscle Response<span>7.1 → 18.9</span>
                    <span className="improved">Normal</span>
                  </span>
                </li>
                <li>
                  <span className="spacer">
                    Weight<span>192 lbs → 174 lbs</span>
                    <span className="improved">Improved</span>
                  </span>
                </li>
                <li>
                  <span className="spacer">
                    Blood Pressure<span>25/80 → 120/80</span>
                    <span className="improved">Improved</span>
                  </span>
                </li>
                <li>
                  <span className="spacer">
                    HbA1c<span>6.8 → 6.4</span>
                    <span className="improved">Improved</span>
                  </span>
                </li>
              </ul>
            </div>
          )}
        </React.Fragment>
      );
  }
};

export default Ursula;
