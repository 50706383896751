import robertMaurer from "../../assets/profiles/robertMaurer.jpg";
import lesliFellman from "../../assets/profiles/lesliFellman.jpg";
import robertAubrey from "../../assets/profiles/robertAubrey.jpg";
import brendaChristian from "../../assets/profiles/brendaChristian.jpg";
import barryGinsberg from "../../assets/profiles/barryGinsberg.jpg";
import andrewMaurer from "../../assets/profiles/andrewMaurer.jpg";
import alexMcpherson from "../../assets/profiles/alexMcpherson.jpg";
import peterOzug from "../../assets/profiles/peterOzug.jpg";

const robert = {
  bio:
    // eslint-disable-next-line
    "Robert has over 45 years of experience in diagnostics and biopharma. \
  He began his career with 17 years at Abbott Diagnostics, initially in sales \
  in New York City.  He spent five years based in Hong Kong setting up the diagnostics \
  business in eleven countries covering all of East and South Asia, excluding Japan. \
  He later held management positions at Abbott Diagnostics in charge of worldwide cancer \
  test marketing, as a venture manager for neurological and psychiatric diagnostic tests, \
  and in strategic planning.  Robert was the business Founder and COO of an Alzheimer’s \
  biopharmaceutical company, which in the span of four years progressed from foundation \
  to a partnership with Upjohn.  Robert has held Business Development positions \
  (VP/CBO) in several biopharmaceutical and diagnostic companies, including Ciphergen \
  Biosystems.  As VP Business Development at Ciphergen, Robert created and managed \
  pharmaceutical services and diagnostics businesses.  He built a research services project \
  business producing custom multi-marker diagnostic analyses for major pharmaceutical companies, \
  and performing custom marker discovery projects for a variety of commercial, government, and \
  academic clients.  Additionally, he ran the business side of the ovarian cancer diagnostic \
  program, from initiation of the discovery project with Johns Hopkins through completion of a \
  multi-site, 500+ patient proof of principle clinical study.  In the course of his career, \
  Robert has been responsible for the launch of ten novel diagnostic assays, as well as \
  the development of three other novel tests.  He has held sales, \
  sales management, and marketing responsibility for the launch of numerous commercial kits.  \
  He has extensive out-licensing experience, and has consulted on strategic and marketing \
  matters for fourteen clients. Robert is the lead inventor of the company’s issued algorithm patent, which is a key foundation of the TIGAR reporting platform. <br /><br/>\
    Robert holds a B.A. in Economics and Mathematics from Carleton College, and an MBA from Harvard University.",
  image: robertMaurer,
  name: "Robert Maurer",
  title: "Chairman and CEO, and Founder",
};

export const row1 = [
  robert,
  {
    bio: `Lesli has over 40 years of experience in strategy, finance and developing 
    collaborations. Her career began as a tax consultant at Deloitte & Touche, an 
    international accounting firm where she earned her CPA. At the investment banking 
    firm Lehman Brothers in New York, Lesli worked in corporate finance on initial 
    public offerings, public and private underwritings and merger and acquisition 
    transactions. Many of her clients were in the healthcare, high technology and 
    retailing sectors. Subsequently, she was a Partner at ClearCreek Partners, a 
    boutique financial consulting group with a focus on early stage high tech 
    companies. Lesli’s real estate career spans over 20 years with expertise in 
    development, pension fund advisory, land acquisition and consulting. Her real 
    estate experience includes working at Sentinel Real Estate Corporation, one of 
    the leading multifamily pension fund advisory firms in the US, where she acquired 
    and managed over 1 million sq. ft. in multifamily and corporate properties in the 
    U.S., and Colliers International where she created a unique real estate practice working
     on mixed-use real estate transactions and selling conservation land to large homebuilders.
     Lesli has spent the last 12 years in diabetes clinical decision support, working on business development and alliances, 
     strategy, marketing and customer support, and every aspect of accounting and finance.
    <br /><br />
    Lesli earned an MBA from the Wharton School of the University of Pennsylvania. She holds 
    a Bachelor of General Studies degree from the University of Michigan, 
    with a concentration in Accounting and Art History, and a Master’s in Business 
    Taxation from the University of Southern California.`,
    image: lesliFellman,
    name: "Lesli Fellman",
    title: "Chief of Strategy/Finance, and Founder",
  },
  {
    bio: `Bob joined TIGAR in July 2010, running operations in Canada, and business development projects worldwide.   
    He has had a major role in developing the TIGAR reporting platform, particularly the content databases.
    He has extensive experience in medical products, biotechnology 
    and diagnostics, particularly in oncology. He worked at Abbott 
    Laboratories for over 12 years, where he led worldwide marketing 
    and support for CEA testing in the Cancer Business Unit. He spent 
    over 16 years at Biomira, now Oncothyreon, where he drove the first 
    pharmaceutical-like prospective randomized trial for a diagnostic. The 
    trial demonstrated that Biomira’s breast cancer marker was the first cancer 
    antigen providing a minimum of 10 months advance warning of recurrent disease. 
    Bob led the licensing of this test to several diagnostic companies, and 
    later sold this business to Centocor. At Biomira he led negotiations with 
    Merck KGaA, licensing two late stage cancer vaccines in the largest 
    worldwide alliance in that field at the time, and negotiated numerous 
    license agreements for the firm. He has broad experience in selling, managing 
    a sales force, international diagnostic marketing, and introducing novel cancer 
    diagnostics to the market.
    <br /><br />
    Bob holds a B.S. in Business Administration from Montana State University.
    `,
    image: robertAubrey,
    name: "Robert Aubrey",
    title: "Vice President, Business Development",
  },
];

export const row2 = [
  {
    bio: `Brenda has over 25 years of experience in the biotech industry, from 
    research and product development to late phase (Phase III) clinical trials, with a 
    Project Management Profession (PMP®) accreditation since 2005.  As Director 
    of Project Management at Oncothyreon (formerly Biomira Inc.), Brenda has worked 
    with established biotech companies and managed project teams comprised of mid-size 
    biotech and large pharmaceutical team members across several geographic locations.  
    As Project Leader for the cancer vaccine L-BLP25, she was responsible for development 
    of the vaccine up to the point of Merck acquiring the vaccine at Phase III stage of 
    clinical development. Brenda has the experience to guide the execution of project 
    plans to align with corporate strategy, and the expertise to lead project teams in 
    several cross-functional portfolios, coordinating key activities required to develop 
    and maintain project plans and schedules for all phases of biotech product development. 
    Additionally, she has significant experience in identifying risk factors in the development 
    of products, working with the team to develop mitigation strategies and implement solutions 
    to meet aggressive timelines.  She has developed Project Management Offices in organizations 
    for the purpose of standardizing methodology for managing all corporate projects.
    For the last 12 years Brenda led project management for the TIGAR reporting platform development and improvements, 
    as well as product launch planning for our beta-market stage.  Brenda has had a major role in developing the databases for the TIGAR reporting platform.
    <br /><br />
    Brenda has earned a B.S. in Medical Laboratory Science from the University of Alberta.
    `,
    image: brendaChristian,
    name: "Brenda Christian",
    title: "Director of Product Development",
  },
  {
    bio: `Dr. Ginsberg is the Chief Executive Officer of Diabetes Technology Consultants 
    and has over 35 years of experience in the diabetes field. He currently serves as 
    Chairman of the Scientific Advisory Boards at SFC Fluidics and Facet Technologies, 
    and consults with a number of companies. Prior to his time at Diabetes Technology 
    Consultants, Dr. Ginsberg worked at Becton Dickenson for over 17 years, first as the 
    Corporate Medical Director for World Wide Diabetes Care, and then as Vice President 
    of Worldwide Medical Affairs in the Diabetes HealthCare unit. Earlier, he was Principal 
    Investigator of the Diabetes Control and Complications Trial and a Professor of Internal 
    Medicine and Biochemistry at the University of Iowa. He also held positions as Acting 
    Director of the Diabetes-Endocrinology Research Center, and was on the Graduate Council 
    on Nutrition Education. He began his research in diabetes as a Fellow at the NIH in 1974, 
    followed by clinical practice with research in the course of his 13 years at the University 
    of Iowa. Dr. Ginsberg was a Clinical Professor of Medicine at Robert Wood Johnson College of 
    Medicine for 14 years. He presently sits on the Editorial Board of the Journal of Diabetes 
    Science and Technology, is a Technical Advisor for the JDRF Artificial Pancreas Project and 
    consults with the American Diabetes Association. He has served on numerous other medical, 
    editorial, and corporate boards. Dr. Ginsberg is well published in a variety of medical journals, 
    with almost 200 publications.
    <br /><br />
    Dr. Ginsberg earned a B.A. in Chemistry, Magna Cum Laude, at Harpur College SUNY Binghamton. 
    He also earned both an M.D. and a Ph.D. in Molecular Biology from Albert Einstein College of 
    Medicine, Bronx, NY. He was a resident in Internal Medicine at Harvard University at Beth Israel 
    Hospital and was a fellow in Diabetes and Endocrinology at the Diabetes Branch of National 
    Institutes of Health.`,
    image: barryGinsberg,
    name: "Barry Ginsberg, M.D., Ph.D.",
    title: "Medical Director",
  },
  {
    bio: `Andrew began his career in the US Army as an information support specialist 
    in Iraq and Korea earning numerous distinctions and awards, and was honorably discharged. 
    He currently works at Centarus Inc. as the Operations Manager and Virtual Chief Information 
    Officer, helping the firm’s numerous clients map industry solutions to their IT needs.  
    Previously, he has worked for Birkenstock USA as an IT systems administrator prior to going 
    to Columbia University in New York City.
    <br /><br />
    Andrew holds a B.A. in Economics from Columbia University and is a Microsoft Certified Systems 
    Engineer.
    `,
    image: andrewMaurer,
    name: "Andrew Maurer",
    title: "Director of IT",
  },
];

export const row3 = [
  robert,
  {
    bio: `Dr. Alex McPherson has had a distinguished career in both medicine and business. He served 
    as President and CEO of Biomira, Inc. from October of 1991 until his retirement in 
    May 2006. He joined the Board of Directors of Biomira in 1987, and was later appointed 
    as CEO. He has served as a professor for 25 years at the University of Alberta (Canada) 
    and is currently Professor Emeritus. Dr. McPherson was the Director of Medicine at 
    the Cross Cancer Institute in Edmonton from 1972-1985, and the Deputy Minister of 
    Health in Alberta, the highest appointed position in the Provincial Department of 
    Health with 2,500 employees and a budget of over $5 billion. He also served as Deputy 
    Commissioner and Executive Director of the Premier’s Commission on Future Health Care 
    for Albertans from 1985 – 1990. In previous years he was elected as President of the 
    Alberta Medical Association and the Canadian Medical Association. Dr. McPherson has 
    served as Director of Biotechnology Industry Organization (BIO), Biotec Canada and 
    the Alberta Biotech Association. He currently sits on the board of several public and 
    private companies. He is an immuno-oncologist and has the following degrees and 
    professional qualifications: FRACP (Australasian College of Physicians; FACP (American College of Physicians); 
    and FRCP(C) Canadian College of Physicians.
    <br /><br />
    Dr. McPherson earned his medical degree from the University of Alberta, Canada in 1962, 
    and his PhD from the University of Melborne in Australia in 1969.`,
    image: alexMcpherson,
    name: "Alex McPherson M.D., Ph.D.",
    title: "",
  },
  {
    bio: `Peter Ozug is, with Joanne Ozug, a major investor in the company.  
    <br /><br />
    Peter has worked as a public and private company investor, and alongside his wife on her businesses, 
    <a href="https://fifteenspatulas.com" target="_blank">Fifteen Spatulas</a> and The Road To Carnivore, 
    since separating from the Navy.
    <br /><br />
    Previously, Peter served eight years in the Navy as a Submarine Officer. 
    He served on the USS Alaska as the Tactical Systems Officer where he supervised sailors in maintenance and operations. 
    There, the Department of Naval Reactors qualified him as a Nuclear Engineer. 
    He then went on to the USS North Dakota as the Assistant Weapons Officer during construction of the ship. 
    At his last command, he was an Assistant Professor at SUNY Maritime, Fordham University, and Columbia University. 
    There, he taught Naval Engineering and Weapons Systems and mentored future Naval Officers.
    <br /> <br />
    Peter has a B.A. in Economics from the University of Rochester and a Master’s in Media Management from Fordham University.
    `,
    image: peterOzug,
    name: "Peter Ozug",
    title: "Lead Investor",
  },
];

export const condensed1 = {
  bio: `Dr. Bloomgarden is in clinical practice in New York City and specializes in 
  diabetes. He is a Clinical Professor in the Department of Medicine, Division of 
  Endocrinology, Mount Sinai School of Medicine. R. Bloomgarden is a member of the 
  Board of the American Association of Clinical Endocrinologists (AACE), is the 
  co-Editor of the Journal of Diabetes, and has been principal investigator in 
  numerous studies assessing anti-diabetic agents. (+ More) He has spoken at a wide 
  range of institutions and conferences in the U. S. and internationally, held many 
  roles with medical journals and professional associations, and has been an advisor 
  to a number of pharmaceutical companies.  Dr. Bloomgarden has an international reputation 
  for writing and lecturing on diabetes, and has authored over 350 publications in this field.`,
  name: "Zachary Bloomgarden, MD, FACE",
};

export const condensed2 = {
  bio: `Dr. Bohannon previously has been in private practice specializing in research in and 
  treatment of diabetes and metabolic disorders. She has continued to see long term patients.  Dr. Bohannon has been Principal Investigator 
  on over 70 clinical trials, involving devices and drugs for diabetes, hypertension, obesity 
  and dyslipidemia, serves on numerous editorial and review boards, and has been an advisor to 
  a number of companies. (+ More)  She has held many professional roles over the course of her career, 
  including president of the Medical Society of the United States and Mexico.  Dr. Bohannon has 
  over 75 publications, and has spoken extensively in the U. S. and internationally.`,
  name: "Nancy Bohannon, MD, FACP, FACE",
};

export const condensed5 = {
  bio: `Dr. Herbert Fritsche is one of the world’s leading experts on tumor biomarkers. 
  Recently, he has worked extensively on laboratory test result reporting and interpretation, 
  including pattern recognition, algorithms, and related software development, connected with 
  personalized medicine in oncology.  Dr. Fritsche served over 40 years at the University of 
  Texas, M.D. Anderson Cancer Center in Houston,TX (until 2010) as the Professor of Laboratory 
  Medicine and Chief of the Clinical Chemistry Section. (+ More) During his long tenure at M.D. 
  Anderson Cancer Center, Dr. Fritsche focused his research activities on the development and 
  validation of cancer diagnostics.  He has been involved with the commercialization of every 
  serum cancer biomarker product in use in the United States, and many of the new tissue based 
  molecular markers. He has served as President of the Clinical Ligand Assay Society (CLAS) and 
  is a Fellow of the National Academy of Clinical Biochemistry.  Dr. Fritsche has served on the 
  Expert Panel for Tumor Marker Practice Guidelines for the American Society of Clinical Oncology 
  (ASCO), the Laboratory Practice Guidelines Committee for the National Academy of Clinical 
  Biochemistry, as a consultant/advisor to the National Cancer Institute (NCI) and the U.S. 
  Food and Drug Administration (FDA).  He has also consulted with many major international 
  diagnostic companies and biotech start-up companies.  Dr. Fritsche has published over 200 
  peer-reviewed scientific papers, invited articles and book chapters, holds three patents, 
  and serves on the Editorial Board of six international scientific journals.`,
  name: "Herbert Fritsche, Jr., Ph.D.",
};

export const condensed7 = {
  bio: `Dr. Handelsman is the medical director and principal investigator of the Metabolic 
  Institute of America, and an endocrinologist in solo private practice in Tarzana, California.  
  He is an internationally recognized expert on diabetes treatment and its complications.  Dr. 
  Handelsman is a Past President of the American Association of Clinical Endocrinologists, and 
  has been a leader in the development of professional guidelines. (+ More) He held a wide 
  range of roles in professional organizations, and has published over 110 peer reviewed, original 
  research & review papers, and many other publications. He speaks extensively at conferences in 
  the U.S..  Dr. Handelsman has been an editor or reviewer for multiple medical journals and has 
  advised numerous companies. He’s well recognized as the founder and organizer of two noted annual 
  clinical conferences – the World Congress of Insulin Resistance, Diabetes, and Cardiovascular 
  Disease (WCIRDC), and the recently developed Heart in Diabetes which addresses the overlapping 
  issues of diabetes and cardiovascular disease.`,
  name: "Yehuda Handelsman, MD, FACP, FNLA, FASPC, MACE",
};

export const condensed8 = {
  bio: `Harry Rittenhouse, PhD, is an advisor in Assay Development, and previously had joined 
  the company in 2012.  He is a worldwide authority on prostate cancer testing, He started his 
  corporate career at Abbott Diagnostics, focused in cancer research and immunoassay development.  
  He then became head of In Vitro Diagnostics Cancer Research for Specialty Laboratories in California, 
  and worked at Hybritech for six years as the head of In Vitro Diagnostics Cancer Research.
  (+ More) He led the collaboration on PSA between Hybritech and the SRL reference lab in Japan.  
  Dr. Rittenhouse then worked as Senior Staff Scientist, Cancer Research at Beckman Coulter.  He 
  led his research team to identify components of free PSA for more specific detection of aggressive 
  prostate cancer and a molecular form of free PSA, BPH PSA, for the management of clinical BPH.  
  Dr. Rittenhouse then joined Gen-Probe and as Senior Director, Cancer Program, focused on PCA3 and 
  other prostate cancer management tests.  He led his research team using molecular diagnostic 
  measurement of cancer genes to detect cancer cells directly in biological fluids.  This led to FDA 
  approval for the leading novel molecular urine test for prostate cancer, utilizing the over-expression 
  of the PCA3 gene. He has written numerous publications, and is an inventor for several issued patents.`,
  name: "Harry Rittenhouse, Ph.D.",
};
