import React, { Component } from "react";
import classNames from "classnames";
import "./profileRow.css";

class ProfileRow extends Component {
  state = { bioOpen: false, curProfile: 0 };

  setProfile = ({ currentTarget }) => {
    this.props.activateRow(this.props.row);
    const curProfile = parseInt(currentTarget.getAttribute("data-profile"));
    this.setState({ bioOpen: true, curProfile });
  };

  render() {
    const { profiles, active } = this.props;
    const { bioOpen, curProfile } = this.state;

    return (
      <div className="profile-row-wrapper">
        <div className="row-wrapper">
          {profiles.map(({ image, name, title }, index) => (
            <span
              className="profile-wrapper"
              data-profile={index}
              key={`${name}-${index}`}
              onClick={this.setProfile}
            >
              <img src={image} alt="" />
              <h4>{name}</h4>
              <h5>{title}</h5>
            </span>
          ))}
        </div>
        <div className={classNames("bio-wrapper", active && "active")}>
          {active && bioOpen && (
            <React.Fragment>
              <article className={`bio-marker-${curProfile}`} />
              <p
                dangerouslySetInnerHTML={{ __html: profiles[curProfile].bio }}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default ProfileRow;
