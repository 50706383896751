import React, { Component } from "react";
import classNames from "classnames";
import "./patient.css";

class Patient extends Component {
  render() {
    const {
      activatePatient,
      activeSlide,
      activated,
      age,
      backgroundSrc,
      children,
      condition,
      deactivatePatient,
      headline,
      name,
      patientNo,
      setSlide,
    } = this.props;

    return (
      <div className={classNames("patient-frame", activated && "activated")}>
        <a id={`${name.toLowerCase()}`} href={`#${name.toLowerCase()}`}>
          <div
            className="patient-wrapper"
            style={{ backgroundImage: "url(" + backgroundSrc + ")" }}
          >
            {!activated && (
              <div>
                <span>
                  <h1>{name}</h1>
                  <h4>{condition}</h4>
                  <small>Age {age}</small>
                </span>
                <button onClick={activatePatient} data-patientno={patientNo}>
                  VIEW
                </button>
              </div>
            )}
          </div>
          {activated && (
            <div className="activated-content">
              <h1>
                {name}, age {age}
              </h1>
              <p className="headline">{headline}</p>
              <div>
                <button
                  className={classNames(activeSlide === 0 && "active")}
                  data-slide={0}
                  data-patientno={patientNo}
                  onClick={setSlide}
                >
                  Initial Condition
                </button>
                <button
                  className={classNames(activeSlide === 1 && "active")}
                  data-slide={1}
                  data-patientno={patientNo}
                  onClick={setSlide}
                >
                  Test Results
                </button>
                <button
                  className={classNames(activeSlide === 2 && "active")}
                  data-slide={2}
                  data-patientno={patientNo}
                  onClick={setSlide}
                >
                  What Changed
                </button>
                <button
                  className={classNames(activeSlide === 3 && "active")}
                  data-slide={3}
                  data-patientno={patientNo}
                  onClick={setSlide}
                >
                  Six Months Later
                </button>
              </div>
            </div>
          )}
          {activated && (
            <div className="solution-children-wrapper">
              {children}
              <small
                className="minimize-patient"
                data-patientno={patientNo}
                onClick={deactivatePatient}
              >
                Hide
              </small>
            </div>
          )}
        </a>
      </div>
    );
  }
}

export default Patient;
