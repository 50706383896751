import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../Home";
import About from "../About";
import Contact from "../Contact";
import Header from "../Header";
import Solution from "../Solution";
import Footer from "../Footer";
import Publications from "../Publications";
import Invest from "../Invest";
import "./style.css";

const Root = () => (
  <Router>
    <div>
      <Route path="/" component={Header} />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/solution" component={Solution} />
        <Route path="/publications" component={Publications} />
        <Route path="/team" component={About} />
        <Route path="/investors" component={Invest} />
        <Route path="*" component={Home} />
      </Switch>
      <Route path="/" component={Contact} />
      <Route path="/" component={Footer} />
    </div>
  </Router>
);

export default Root;
