import React, { Component } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
// import logo from "../../assets/logo.png";
import hamburger from "../../assets/hamburger.png";
import close from "../../assets/close.png";
import "./style.css";

class Header extends Component {
  state = { menuOpen: false };

  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  scrollToContact = () => {
    window.scrollTo({ behavior: "smooth", top: document.body.scrollHeight });
    this.setState({ menuOpen: false });
  };

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  render() {
    const { menuOpen } = this.state;

    return (
      <div className="header-wrapper">
        <Link to="/" className="company-name-section">
          <div className="logo" />
        </Link>
        <div className="header-links">
          <Link to="/" className="header-link">
            Home
          </Link>
          <Link to="/solution" className="header-link">
            Our Solution
          </Link>
          <Link
            to="/publications"
            className="header-link"
            onClick={this.closeMenu}
          >
            Publications
          </Link>
          <Link to="/team" className="header-link">
            Team
          </Link>
          <Link to="/investors" className="header-link">
            Investors
          </Link>
          <a href="#contact" onClick={this.scrollToContact} className="contact">
            Contact
          </a>
        </div>
        <img
          className={classNames("hamburger-menu", menuOpen && "close")}
          src={menuOpen ? close : hamburger}
          alt=""
          onClick={this.toggleMenu}
        />
        <div className={classNames("mobile-menu", menuOpen && "active")}>
          {menuOpen && (
            <React.Fragment>
              <a
                href="#contact"
                onClick={this.scrollToContact}
                className="contact"
              >
                Contact
              </a>
              <Link
                to="/investors"
                className="header-link"
                onClick={this.closeMenu}
              >
                Investors
              </Link>
              <Link to="/team" className="header-link" onClick={this.closeMenu}>
                Team
              </Link>
              <Link
                to="/publications"
                className="header-link"
                onClick={this.closeMenu}
              >
                Publications
              </Link>
              <Link
                to="/solution"
                className="header-link"
                onClick={this.closeMenu}
              >
                Our Solution
              </Link>
              <Link to="/" className="header-link" onClick={this.closeMenu}>
                Home
              </Link>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Header;
