import React, { Component } from "react";
import classNames from "classnames";
import benefitsDecoration from "../../assets/blurb-decoration.png";
import benefits0 from "../../assets/benefits0.jpg";
import benefits1 from "../../assets/benefits1.jpg";
import benefits2 from "../../assets/benefits2.jpg";
import benefits3 from "../../assets/benefits3.jpg";
import "./benefits.css";

const images = [benefits0, benefits1, benefits2, benefits3];

class Benefits extends Component {
  state = { currentBenefit: 0 };

  benefitBullets = {
    0: [
      "Delivers personalized assessment to better understand patient condition",
      "Replaces trial and error drug selection",
      "TIGAR is easy to use and saves time",
      "Promotes better care to patients",
      "Enhances physician image to patient",
      "TIGAR Patient report encourages patient engagement and compliance"
    ],
    1: [
      "TIGAR increases testing and patient management revenue",
      "Improves quality of care outcomes for patients",
      "Assists in diabetes focused population health programs",
      "Increases cost savings"
    ],
    2: [
      "Delivers personalization and better patient engagement experience",
      "Supports assessment of health and differentiation of quality of care",
      "Promotes better patient management",
      "Heightened focus on health and wellness, not just illness",
      "Reduces overall cost of patient healthcare"
    ],
    3: [
      "Expands existing diabetes lab offerings",
      "Adds unique menu item in a large disease",
      "Increases revenue with more testing per sample",
      "Enhances client services and images"
    ]
  };

  renderBenefit() {
    const { currentBenefit } = this.state;

    return (
      <div className="benefit-wrapper">
        <img className="benefit-image" src={images[currentBenefit]} alt="" />
        <ul>
          {this.benefitBullets[currentBenefit].map((bullet, index) => (
            <li key={`${index}-bullet`}>
              <span>{bullet}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  setBenefit = ({ currentTarget }) => {
    const { id } = currentTarget;
    this.setState({ currentBenefit: parseInt(id) });
  };

  render() {
    const { currentBenefit } = this.state;

    return (
      <div className="benefits-wrapper">
        <h1>Benefits to Our Partners:</h1>
        <div className="benefits-buttons-wrapper">
          <button
            id={0}
            className={classNames(currentBenefit === 0 && "active")}
            onClick={this.setBenefit}
          >
            Physicians
          </button>
          <button
            id={1}
            className={classNames(currentBenefit === 1 && "active")}
            onClick={this.setBenefit}
          >
            Health Systems
          </button>
          <button
            id={2}
            className={classNames(currentBenefit === 2 && "active")}
            onClick={this.setBenefit}
          >
            Concierge Medicine
          </button>
          <button
            id={3}
            className={classNames(currentBenefit === 3 && "active")}
            onClick={this.setBenefit}
          >
            Laboratories
          </button>
        </div>
        {this.renderBenefit()}
        <img className="tigar-stamp" src={benefitsDecoration} alt="" />
        <h1 className="benefit-header-bottom">
          TIGAR leads to optimal individualized therapy improving a patient's
          glucose control and health.
        </h1>
      </div>
    );
  }
}

export default Benefits;
