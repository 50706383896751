import React, { Component } from "react";
import "./condensedProfile.css";

class CondensedProfile extends Component {
  state = {
    bio: "",
    expanded: false
  };

  componentWillMount() {
    this.shortenBio();
  }

  expandBio = () => {
    const { bio } = this.props;
    const cutoff = bio.indexOf("(+ More)");
    const fullBio = bio.slice(0, cutoff) + bio.slice(cutoff + 8, bio.length);
    this.setState({ bio: fullBio, expanded: true });
  };

  getShortenedBio = () => {
    const { bio } = this.props;
    const cutoff = bio.indexOf("(+ More)");
    return bio.slice(0, cutoff);
  };

  shortenBio = () => {
    this.setState({ bio: this.getShortenedBio(), expanded: false });
  };

  render() {
    const { expanded } = this.state;
    return (
      <div className="condensed-wrapper">
        <h4>{this.props.name}</h4>
        <p>{this.state.bio}</p>
        <div className="add-more-wrapper">
          {expanded ? (
            <React.Fragment>
              <article onClick={this.shortenBio}>-</article>
              <h5 onClick={this.shortenBio}>Hide</h5>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <article onClick={this.expandBio}>+</article>
              <h5 onClick={this.expandBio}>More</h5>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default CondensedProfile;
