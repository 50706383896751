import React from "react";

const Tim = ({ activeSlide }) => {
  switch (activeSlide) {
    case 0:
      return (
        <div className="list-split">
          <ul>
            <li>
              <span>
                Weight: <span className="danger">15 pounds overweight</span>
              </span>
            </li>
            <li>
              <span>
                Blood Pressure: <span className="danger">elevated</span>
              </span>
            </li>
            <li>
              <span>
                HbA1c: 5.6 vs. {"<"} 6.0 <span className="danger">normal</span>
              </span>
            </li>
          </ul>
          <ul>
            <li>
              <span>
                On Lipitor and aspirin{" "}
                <span className="danger">several years</span>
              </span>
            </li>
            <li>
              <span>
                Cardiovascular condition{" "}
                <span className="danger">quickly deteriorating</span>
              </span>
            </li>
          </ul>
        </div>
      );
    case 1:
      return (
        <React.Fragment>
          <h2>TIGAR uncovers major dysfunction in Tim</h2>
          <ul className="restricted-list">
            <li>
              <span className="spacer">
                Vascular Inflammation <span className="danger">7X normal</span>
              </span>
            </li>
            <li>
              <span className="spacer">
                Insulin Production (Beta-cell){" "}
                <span className="danger">4X normal</span>
              </span>
            </li>
            <li>
              <span className="spacer">
                Fat & Muscle Response
                <span className="danger">50% of normal</span>
              </span>
            </li>
          </ul>
        </React.Fragment>
      );
    case 2:
      return (
        <React.Fragment>
          <h2>
            Physician <u>began</u> targeted therapy:
          </h2>
          <ul>
            <li>
              <span>Continues Lipitor and aspirin</span>
            </li>
            <li>
              <span>
                <span className="danger">Added:</span> Pioglitazone (TZD){" "}
              </span>
            </li>
            <li>
              <span>
                <span className="danger">Started</span> low carb diet
              </span>
            </li>
          </ul>
        </React.Fragment>
      );
    default:
      return (
        <React.Fragment>
          <h2>All measures improved especially underlying disease</h2>
          {window.innerWidth > 600 ? (
            <div className="list-split expand triple">
              <ul>
                <li>
                  <span>Vascular Inflammation</span>
                </li>
                <li>
                  <span>Proinsulin Production</span>
                </li>
                <li>
                  <span>Fat and Muscle Response</span>
                </li>
                <li>
                  <span>Weight</span>
                </li>
                <li>
                  <span>Blood Pressure</span>
                </li>
                <li>
                  <span>HbA1c</span>
                </li>
              </ul>
              <ul className="no-list">
                <li>
                  <span>7.3 → 0.9</span>
                </li>
                <li>
                  <span>17.2 → 4.2</span>
                </li>
                <li>
                  <span>4.8 → 14.3</span>
                </li>
                <li>
                  <span>220 lbs → 215 lbs</span>
                </li>
                <li>
                  <span>135/85 → 125/80</span>
                </li>
                <li>
                  <span>5.6 → 5.4</span>
                </li>
              </ul>
              <ul className="no-list">
                <li>
                  <span className="improved">Normal</span>
                </li>
                <li>
                  <span className="improved">Normal</span>
                </li>
                <li>
                  <span className="improved">Normal</span>
                </li>
                <li>
                  <span className="improved">Improved</span>
                </li>
                <li>
                  <span className="improved">Improved</span>
                </li>
                <li>
                  <span className="improved">Improved</span>
                </li>
              </ul>
            </div>
          ) : (
            <div className="list-split expand">
              <ul className="mobile">
                <li>
                  <span className="spacer">
                    Vascular Inflammation<span>7.3 → 0.9</span>
                    <span className="improved">Normal</span>
                  </span>
                </li>
                <li>
                  <span className="spacer">
                    Proinsulin Production<span>17.2 → 4.2</span>
                    <span className="improved">Normal</span>
                  </span>
                </li>
                <li>
                  <span className="spacer">
                    Fat and Muscle Response<span>4.8 → 14.3</span>
                    <span className="improved">Normal</span>
                  </span>
                </li>
                <li>
                  <span className="spacer">
                    Weight<span>220 lbs → 215 lbs</span>
                    <span className="improved">Improved</span>
                  </span>
                </li>
                <li>
                  <span className="spacer">
                    Blood Pressure<span>135/85 → 125/80</span>
                    <span className="improved">Improved</span>
                  </span>
                </li>
                <li>
                  <span className="spacer">
                    HbA1c<span>5.6 → 5.4</span>
                    <span className="improved">Improved</span>
                  </span>
                </li>
              </ul>
            </div>
          )}
        </React.Fragment>
      );
  }
};

export default Tim;
