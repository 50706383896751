import React from "react";
import summary from "../../assets/summary.png";
import "./summary.css";

const Summary = () => (
  <div className="summary-wrapper">
    <p>
      Physicians can make expert decisions based on the TIGAR Report and their
      medical judgment using our 'state-of-the-art' system.
    </p>
    <img src={summary} alt="" />
  </div>
);

export default Summary;
