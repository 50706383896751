import React, { Component } from "react";
import slide0Active from "../../assets/slide0Active.png";
import slide0Inactive from "../../assets/slide0Inactive.png";
import slide1Inactive from "../../assets/slide1Inactive.png";
import slide1Active from "../../assets/slide1Active.png";
import slide2Inactive from "../../assets/slide2Inactive.png";
import slide2Active from "../../assets/slide2Active.png";
import slide3Inactive from "../../assets/slide3Inactive.png";
import slide3Active from "../../assets/slide3Active.png";
import slide4Inactive from "../../assets/slide4Inactive.png";
import slide4Active from "../../assets/slide4Active.png";
import "./tigarPlan.css";

class TigarPlan extends Component {
  state = { currentSlide: 0, width: window.innerWidth };

  componentDidMount() {
    window.addEventListener("resize", this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth);
  }

  updateWidth = () => {
    if (
      (this.state.width > 800 && window.innerWidth < 800) ||
      (this.state.width < 800 && window.innerWidth > 800)
    ) {
      this.setState({ width: window.innerWidth });
    }
  };

  moveToSpecificSlide = ({ currentTarget }) => {
    this.setState({ currentSlide: parseInt(currentTarget.id) });
  };

  setImageRef = (ref) => {
    this.imageRef = ref;
  };

  setRef = (ref) => {
    this.coverRefs[`cover${ref.id}`] = ref;
  };

  render() {
    const { currentSlide, width } = this.state;

    return (
      <div className="tigar-plan-wrapper">
        <h1>How Does TIGAR Work?</h1>
        <div className="tigar-plan-content-wrapper">
          {width > 800 && (
            <div className="slides-wrapper">
              <img
                alt=""
                id={0}
                onClick={this.moveToSpecificSlide}
                src={currentSlide === 0 ? slide0Active : slide0Inactive}
              />
              <img
                alt=""
                id={1}
                onClick={this.moveToSpecificSlide}
                src={currentSlide === 1 ? slide1Active : slide1Inactive}
              />
              <img
                id={2}
                src={currentSlide === 2 ? slide2Active : slide2Inactive}
                onClick={this.moveToSpecificSlide}
                alt=""
              />
              <img
                id={3}
                src={currentSlide === 3 ? slide3Active : slide3Inactive}
                onClick={this.moveToSpecificSlide}
                alt=""
              />
              <img
                id={4}
                src={currentSlide === 4 ? slide4Active : slide4Inactive}
                onClick={this.moveToSpecificSlide}
                alt=""
              />
            </div>
          )}
          <ul>
            <li
              id={0}
              className={currentSlide === 0 ? "active-slide" : ""}
              onClick={this.moveToSpecificSlide}
            >
              <article>1</article>
              <h3>
                Physician requests the TIGAR tests and Report, which includes
                HbA1c
              </h3>
            </li>
            <li
              id={1}
              className={currentSlide === 1 ? "active-slide" : ""}
              onClick={this.moveToSpecificSlide}
            >
              <article>2</article>
              <h3>Patient gets blood drawn at a laboratory</h3>
            </li>
            <li
              id={2}
              className={currentSlide === 2 ? "active-slide" : ""}
              onClick={this.moveToSpecificSlide}
            >
              <article>3</article>
              <h3>
                Laboratory runs the tests and enters results into the TIGAR
                Health Technologies web portal
              </h3>
            </li>
            <li
              id={3}
              className={currentSlide === 3 ? "active-slide" : ""}
              onClick={this.moveToSpecificSlide}
            >
              <article>4</article>
              <h3>
                Results are analyzed through TIGAR’s proprietary algorithm
              </h3>
            </li>
            <li
              id={4}
              className={currentSlide === 4 ? "active-slide" : ""}
              onClick={this.moveToSpecificSlide}
            >
              <article>5</article>
              <h3>
                TIGAR Report is generated and sent to our Partner (or Physician)
              </h3>
            </li>
          </ul>
        </div>
        <div className="tigar-plan-footer">
          We can integrate with existing EMR platforms,
          <br /> and not disturb existing workflows.
        </div>
      </div>
    );
  }
}

export default TigarPlan;
