import React from "react";
import image from "../../assets/solution2.jpg";
import "./snippet.css";

const Snippet = () => (
  <div className="snippet-wrapper">
    <p>
      “TIGAR offers a whole new <br />
      perspective on our patients.”
    </p>
    <img src={image} alt="" />
  </div>
);

export default Snippet;
