import React from "react";
import "./style.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer-wrapper">
      <span />
      <span>
        <p>© {currentYear} TIGAR Health Technologies All Rights Reserved</p>
      </span>
    </div>
  );
};

export default Footer;
