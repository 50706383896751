import React from "react";
import "./splash.css";

const Splash = () => (
  <div className="splash-pubs-wrapper">
    <div />
    <h1>
      Published Research Supports
      <br /> the TIGAR Health Solution
    </h1>
  </div>
);

export default Splash;
