import React, { Component } from "react";
import Splash from "./splash";
import Summary from "./summary";
import Snippet from "./snippet";
import Patient from "./patient";
import Ursula from "./ursula";
import ursulaPic from "../../assets/patients/ursula.jpg";
import Tim from "./tim";
import timPic from "../../assets/patients/tim.jpg";
import "./style.css";

class Solution extends Component {
  state = {
    activated: { 0: false, 1: false },
    currentSlide: {
      0: 0,
      1: 0,
    },
  };

  activatePatient = ({ currentTarget }) => {
    const patientNo = parseInt(currentTarget.getAttribute("data-patientno"));
    const { activated } = this.state;
    activated[patientNo] = true;
    this.setState({ activated });
  };

  deactivatePatient = ({ currentTarget }) => {
    const patientNo = parseInt(currentTarget.getAttribute("data-patientno"));
    const { activated } = this.state;
    activated[patientNo] = false;
    this.setState({ activated });
  };

  setSlide = ({ currentTarget }) => {
    const patientNo = parseInt(currentTarget.getAttribute("data-patientno"));
    const slide = parseInt(currentTarget.getAttribute("data-slide"));
    this.setState({
      currentSlide: {
        ...this.state.currentSlide,
        [patientNo]: slide,
      },
    });
  };

  render() {
    const { activated, currentSlide } = this.state;

    return (
      <React.Fragment>
        <Splash />
        <div className="our-solution-wrapper">
          <h2>
            TIGAR Health has created a clinical decision support system for
            physicians based on pathophysiology for individualized therapy. It
            seamlessly integrates into current practice.
          </h2>
          <Summary />
          <h2>
            Targeted intervention and better drug regimens improve quality of
            health and save costs.
          </h2>
          <Snippet />
          <h2 className="case-studies">Case Studies: The Power of TIGAR</h2>
          <Patient
            activatePatient={this.activatePatient}
            activeSlide={currentSlide[0]}
            activated={activated[0]}
            age="68"
            backgroundSrc={ursulaPic}
            condition="DIABETES"
            deactivatePatient={this.deactivatePatient}
            headline="Late stage diabetes patient looks well controlled but health in slow decline"
            name="Ursula"
            patientNo={0}
            setSlide={this.setSlide}
          >
            <Ursula activeSlide={currentSlide[0]} />
          </Patient>
          <Patient
            activatePatient={this.activatePatient}
            activeSlide={currentSlide[1]}
            activated={activated[1]}
            age="73"
            backgroundSrc={timPic}
            condition="PREDIABETES"
            deactivatePatient={this.deactivatePatient}
            headline="Asymptomatic prediabetes patient with severe underlying disease but not treated today"
            name="Tim"
            patientNo={1}
            setSlide={this.setSlide}
          >
            <Tim activeSlide={currentSlide[1]} />
          </Patient>
        </div>
      </React.Fragment>
    );
  }
}

export default Solution;
