import React from "react";
import "./splash.css";

const Splash = () => (
  <div className="splash-solution-wrapper">
    <div />
    <h1>
      TIGAR Matches the Right Drug
      <br /> to the Right Patient
    </h1>
  </div>
);

export default Splash;
