import React from "react";
import Splash from "./splash";
import Blurb from "./blurb";
import Platform from "./platform";
import Summary from "./summary";
import VideoWithPlaceholder from "../VideoWithPlaceholder";
import TigarPlan from "./tigarPlan";
import CaseStudies from "./caseStudies";
import Benefits from "./benefits";

const home = () => (
  <div className="home-wrapper">
    <Splash />
    <Blurb />
    <Platform />
    <Summary />
    <VideoWithPlaceholder
      iframeTitle="TIGAR Health Testimonial"
      sectionTitle="Patient Testimonial"
      imageKey="patient"
      videoSrc="https://player.vimeo.com/video/375056302"
    />
    <VideoWithPlaceholder
      iframeTitle="CEO Message"
      sectionTitle="CEO Message"
      imageKey="ceo"
      videoSrc="https://player.vimeo.com/video/509363206"
    />
    <VideoWithPlaceholder
      iframeTitle="A Doctor's Perspective"
      sectionTitle="A Doctor's Perspective"
      imageKey="baumann"
      videoSrc="https://player.vimeo.com/video/421402704"
    />
    <VideoWithPlaceholder
      iframeTitle="Gaining control"
      sectionTitle="Gaining control"
      imageKey="mallon"
      videoSrc="https://player.vimeo.com/video/393356615"
    />
    <TigarPlan />
    <CaseStudies />
    <Benefits />
  </div>
);

export default home;
