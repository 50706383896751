import React, { Component } from "react";
import Splash from "./splash";
import "./style.css";
import PubsNav from "./pubsNav";
import PubsSection from "./pubsSection";
import {
  diabetes1,
  diabetes2,
  prediabetes1,
  prediabetes2,
  supporting1,
  supporting2,
  supporting3
} from "./pubs";

class Publications extends Component {
  jumpToArticles = ({ currentTarget }) => {
    const section = currentTarget.getAttribute("data-link");
    const ref = this[section];
    window.scrollTo({ behavior: "smooth", top: ref.offsetTop });
  };

  setDiabetesRef = ref => {
    this.diabetes = ref;
  };

  setPrediabetesRef = ref => {
    this.prediabetes = ref;
  };

  setSupportingStudiesRef = ref => {
    this.supportingStudies = ref;
  };

  render() {
    return (
      <div className="pubs-wrapper">
        <Splash />
        <PubsNav jumpToArticles={this.jumpToArticles} />
        <div className="pub-sections-wrapper">
          <h2 ref={this.setDiabetesRef}>Diabetes</h2>
          <PubsSection rows={[diabetes1, diabetes2]} />
          <h2 ref={this.setPrediabetesRef}>Prediabetes</h2>
          <PubsSection rows={[prediabetes1, prediabetes2]} initialMax={1} />
          <h2 className="secondary" ref={this.setSupportingStudiesRef}>
            Supporting Studies
          </h2>
          <h3 className="secondary">
            Clinical Efficacy and Cost Effectiveness
          </h3>
          <PubsSection
            initialMax={2}
            rows={[supporting1, supporting2, supporting3]}
            secondary
          />
        </div>
      </div>
    );
  }
}

export default Publications;
