export const diabetes1 = [
  {
    author: `Pfützner, et al. (2005)`,
    journal: `Journal of the American College of Cardiology`,
    description: `Improvement of cardiovascular risk markers by pioglitazone 
      is independent from glycemic control (Pioneer study)`,
    link: `https://core.ac.uk/download/pdf/82412619.pdf`
  },
  {
    author: `Pfützner, et al. (2006)`,
    journal: `Clinical Chemistry and Laboratory Medicine`,
    description: `Association of high-sensitivity C-reactive protein in advanced stage 
  beta-cell dysfunction and insulin resistance in patients with type 2 diabetes `,
    link: `https://www.ncbi.nlm.nih.gov/pubmed/16681424`
  },
  {
    author: `Hanefeld, et al. (2011)`,
    journal: `Cardiovascular Diabetology`,
    description: `Multicentre investigation of pioglitazone, metformin, and the 
    combination on cardiovascular risk in type 2 diabetes in basal insulin (PIOCOMB study) `,
    link: `https://www.ncbi.nlm.nih.gov/pubmed/21756323`
  },
  {
    author: `Hohberg, et al. (2009) `,
    journal: `Diabetes Obesity and Metabolism`,
    description: `Successful switch from insulin therapy to treatment with pioglitazone 
    in type 2 diabetes patients with residual beta-cell function (PIOSwitch study)`,
    link: `https://www.ncbi.nlm.nih.gov/pubmed/19422402`
  }
];

export const diabetes2 = [
  {
    author: `Forst, et al., (2010)`,
    journal: `Clinical Science`,
    description: `Pioglitazone in addition to metformin improves erythrocyte 
    deformability in patients with type 2 diabetes`,
    link: `http://www.clinsci.org/content/119/8/345.abstract`
  },
  {
    author: `Zinman, et al. (2015)`,
    journal: `New England Journal of Medicine`,
    description: `Empagliflozin, Cardiovascular Outcomes, and Mortality in Type 2 Diabetes `,
    link: `https://www.ncbi.nlm.nih.gov/pubmed/26378978`
  },
  {
    author: `Steven, et al. [LEADER Trial Investigators] (2016)`,
    journal: `New England Journal of Medicine`,
    description: `Liraglutide and Cardiovascular Outcomes in Type 2 Diabetes`,
    link: `https://www.nejm.org/doi/full/10.1056/NEJMoa1603827`
  },
  {
    author: `Wilcox, et al., (2008)`,
    journal: `American Heart Journal`,
    description: `Effects of pioglitazone on major adverse cardiovascular events 
    in high-risk patients with type 2 diabetes (PROactive 10)`,
    link: `https://www.ncbi.nlm.nih.gov/pubmed/18371481`
  }
];

export const prediabetes1 = [
  {
    author: `Pfützner, et al. (2011)`,
    description: `Effect of Pioglitazone and Ramipril on Biomarkers of Low-Grade Inflammation and 
      Vascular Function in Nondiabetic Patients`,
    journal: `Journal of Diabetes Science and Technology`,
    link: `https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3192606/`
  },
  {
    author: `Forst, et al. (2008)`,
    description: `Investigation of the vascular and pleiotropic effects of atorvastatin 
  and pioglitazone in a population at high cardiovascular risk`,
    journal: `Diabetes and Vascular Disease Research`,
    link: `https://www.researchgate.net/publication/23426508`
  },
  {
    author: `Keman, et al. (2016)`,
    description: `Pioglitazone after ischemic stroke or Transient Ischemic attack`,
    journal: `New England Journal of Medicine`,
    link: `https://www.nejm.org/doi/full/10.1056/NEJMoa1506930`
  },
  {
    author: `Armato, et al. (2018)`,
    description: `Successful treatment of prediabetes in clinical practice using physiological assessment (STOP DIABETES)`,
    journal: `Lancet Diabetes Endocrinology`,
    link: `https://www.thelancet.com/journals/landia/article/PIIS2213-8587(18)30234-1/fulltext`
  }
];

export const prediabetes2 = [
  {
    author: `Pi-Sunyer, et al., (2015)`,
    description: `A Randomized, Controlled Trial of 3.0 mg of Liraglutide in Weight Management`,
    journal: `New England Journal of Medicine`,
    link: `https://www.nejm.org/doi/full/10.1056/NEJMoa1411892`
  },
  {
    author: `Saremi, et al., (2013)`,
    description: `Pioglitazone slows progression of atherosclerosis in prediabetes independent of changes in cardiovascular risk factors`,
    journal: `Arteriosclerosis Thrombosis, and Vascular Biology`,
    link: `https://www.ahajournals.org/doi/abs/10.1161/ATVBAHA.112.300346`
  },
  {},
  {}
];

export const supporting1 = [
  {
    author: "Gæde, et al. (2008)",
    description:
      "Effect of a Multifactorial Intervention on Mortality in Type 2 Diabetes",
    journal: "New England Journal of Medicine ",
    link: "https://www.nejm.org/doi/full/10.1056/NEJMoa0706245"
  },
  {
    author: "Bergeson, et al. (2013)",
    description: `Retrospective Impact of Prior Authorization for Type 2 
    Diabetes Medications on Health Care Costs in a Medicare Population`,
    journal: "Journal of Management Care & Specialty Pharmacy",
    link: "https://www.jmcp.org/doi/10.18553/jmcp.2013.19.5.374"
  },
  {
    author: "Dunlay, et al. (2011)",
    description: `Lifetime Costs of Medical Care after Heart Failure Diagnosis`,
    journal: "Cardiovascular Quality and Outcomes",
    link: "http://europepmc.org/articles/PMC3057895"
  },
  {
    author: "Bonafede, et al. (2015)",
    description: `Medical Costs Associated with Cardiovascular Events 
    Among High-risk Patients with Hyperlipidemia`,
    journal: "ClinicoEconomics and Outcomes Research",
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4467662/"
  }
];

export const supporting2 = [
  {
    author: "Saremi, et al. (2012)",
    description: `Pioglitazone Slows Progression of Atherosclerosis in Prediabetes 
    Independent of Changes in Cardiovascular Risk Factors`,
    journal: "Arteriosclerosis, Thrombosis, and Vascular Biology",
    link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3908828/"
  },
  {
    author: "Knowler, et al. (2002)",
    description: `Reduction in the Incidence of Type 2 Diabetes with 
  Lifestyle Intervention or Metformin`,
    journal: "New England Journal of Medicine",
    link: "https://www.ncbi.nlm.nih.gov/pubmed/11832527"
  },
  {
    author: "Holman, et al. (2017)",
    description: `Effects of Once-Weekly Exenatide on Cardiovascular Outcomes in Type 2 Diabetes`,
    journal: "New England Journal of Medicine",
    link: "https://www.nejm.org/doi/full/10.1056/NEJMoa1612917"
  },
  {
    author: "Marso, et al. (2016)",
    description: `Semaglutide and Cardiovascular Outcomes in Patients with Type 2 Diabetes`,
    journal: "New England Journal of Medicine",
    link: "https://www.nejm.org/doi/full/10.1056/NEJMoa1607141"
  }
];

export const supporting3 = [
  {
    author: "Neal, et al. (2017)",
    description: `Canagliflozin and Cardiovascular and Renal Events in Type 2 Diabetes`,
    journal: "New England Journal of Medicine",
    link: "https://www.nejm.org/doi/full/10.1056/NEJMoa1611925"
  },
  {
    author: "Wiviott, et al. (2018)",
    description: `Dapagliflozin and Cardiovascular Outcomes in Type 2 Diabetes`,
    journal: "New England Journal of Medicine",
    link: "https://www.nejm.org/doi/full/10.1056/NEJMoa1812389"
  },
  {},
  {}
];
