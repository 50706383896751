import React from "react";
import "./style.css";
import Splash from "../Home/splash";

const Invest = () => {
  return (
    <>
      <Splash />
      <div className="invest-wrapper">
        <h1>
          TIGAR Health Technologies is currently looking for institutional
          investors for commercialization
        </h1>
        <p>
          The company has routine practice data, from paid pilot market users,
          across all stages of prediabetes and diabetes, and many different
          clinician specialties. We would be pleased to discuss the opportunity
          with you. Contact for further information.
        </p>
      </div>
    </>
  );
};

export default Invest;
