import React from "react";

const PubsNav = ({ jumpToArticles }) => (
  <React.Fragment>
    <h1>Published Research</h1>
    <div className="pubs-nav">
      Jump to:
      <a data-link="diabetes" href="#Diabetes" onClick={jumpToArticles}>
        Diabetes
      </a>
      |
      <a data-link="prediabetes" href="#Prediabetes" onClick={jumpToArticles}>
        Prediabetes
      </a>
      |
      <a
        data-link="supportingStudies"
        href="#SupportingStudies"
        onClick={jumpToArticles}
      >
        Supporting Studies
      </a>
    </div>
  </React.Fragment>
);

export default PubsNav;
