import React, { Component } from "react";
import "./management.css";
import ProfileRow from "./profileRow";
import CondensedProfile from "./condensedProfile";
import * as profiles from "./profiles";

class Management extends Component {
  state = { activeRow: false };

  hideBio = ({ target }) => {
    if (target.id === "management-wrapper") {
      this.setState({ activeRow: false });
    }
  };

  setActiveRow = (row) => {
    this.setState({ activeRow: row });
  };

  render() {
    const { activeRow } = this.state;

    return (
      <div
        id="management-wrapper"
        className="management-wrapper"
        onClick={this.hideBio}
      >
        <h2>
          TIGAR Health is led by some of the most experienced and innovative
          thinkers in metabolic medicine with a mission to bring individualized
          therapy to diabetes.
        </h2>
        <h3>Management Team</h3>
        <ProfileRow
          active={activeRow === 0}
          activateRow={this.setActiveRow}
          profiles={profiles.row1}
          row={0}
        />
        <ProfileRow
          active={activeRow === 1}
          activateRow={this.setActiveRow}
          profiles={profiles.row2}
          row={1}
        />
        <h3>Board of Directors</h3>
        <ProfileRow
          active={activeRow === 2}
          activateRow={this.setActiveRow}
          profiles={profiles.row3}
          row={2}
        />
        <h3>Clinical and Scientific Advisors</h3>
        <CondensedProfile
          name={profiles.condensed1.name}
          bio={profiles.condensed1.bio}
        />
        <CondensedProfile
          name={profiles.condensed2.name}
          bio={profiles.condensed2.bio}
        />
        <CondensedProfile
          name={profiles.condensed5.name}
          bio={profiles.condensed5.bio}
        />
        <CondensedProfile
          name={profiles.condensed7.name}
          bio={profiles.condensed7.bio}
        />
        <CondensedProfile
          name={profiles.condensed8.name}
          bio={profiles.condensed8.bio}
        />
      </div>
    );
  }
}

export default Management;
